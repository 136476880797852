export const showConfirmationDialog = (confirm, options, callback) => {
  if (process.server) return;

  const defaultOptions = {
    header: "Confirmation",
    blockScroll: true,
    icon: "pi pi-exclamation-triangle",
    acceptIcon: "pi pi-check",
    acceptClass: "!px-5 !py-2.5 !text-sm",
    rejectClass: "!px-5 !py-2.5 !text-sm !text-red-500 !bg-white !border-white",
    rejectIcon: "pi pi-times",
    accept: async () => {
      if (callback) callback();
      confirm.close();
    },
    reject: () => {
      confirm.close();
    },
    onHide: () => {},
  };

  confirm.require({ ...defaultOptions, ...options });
};
